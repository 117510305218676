import apiCall from "../scripts/apiCall";
export default function LoginPage({loginfor, setUserName, page, changePage}) {
    //const title = loginfor === "customer" ? "Müşteri" : "Personel";
    let title;
    switch (loginfor) {
      case "customer":
        title = "Müşteri";
        break;
      case "employee":
        title = "Personel";
        break;
      case "admin":
        title = "Admin";
        break;
      default:
        title = "Müşteri";
        break;
    }

    const submitLogin = (event) => {
      event.preventDefault();
  
      const username = event.target.username.value;
      const password = event.target.password.value;
  
      apiCall(`login/${loginfor}`, { username, password }).then((data) => {
        if(data.success) {
          changePage(loginfor);
          setUserName(username);
        }
        else {
          alert(data.message);
        }
      });
    }
  
    return (
    <div className='row login-row'>
      <div className='col-4'></div>
      <div className='col-4 text-center login-div'>
        <h2>{title} Girişi</h2>
        <form onSubmit={submitLogin}>
          <table>
            <tbody>
              <tr>
                <td>
                  <label>Kullanıcı Adı</label>
                </td>
                <td>
                  <input type='text' name='username' />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Şifre</label>
                </td>
                <td>
                  <input type='text' name='password' />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <button type='submit' className='login-button'>Giriş Yap</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>        
      </div>
      <div className='col-4'></div>
    </div>
  )
}