import apiCall from "../scripts/apiCall";
import copier from "../scripts/copier"
import selectedCopier from "../scripts/selectedCopier";
import { useState, useEffect, ChangeEvent } from 'react'


export default function EmployeePage({page, changePage, username}) {
  const [data, setData] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [userData, setUserData] = useState(null);
  const [fileList, setFileList] = useState(null);

  let selectedFiles = "";


  function copyFiles() {
    copier(userData.os, userData.studio, selectedName);
  }

  function copySelectedFiles() {
    selectedCopier(userData.os, userData.studio, selectedName, userData.path, selectedFiles);
  }

  useEffect(() => {
    apiCall(`getemployee/${username}`).then((empData) => {
      setUserData(empData);
    });
  }, [username]);

  const changeFile = (event) => {
    setFileList(event.target.files);
  }

  //dosya upload fonksiyonu
  function handleFileUpload() {
    if (!fileList) {
      return;
    }
    console.log(fileList);
    const fdata = new FormData();
    files.forEach((file, i) => {
      fdata.append(`files`, file);
    });

    apiCall(`upload-images/${selectedName}`, fdata, "multipart").then((data) => {
      console.log(data);
    });
  };
  const files = fileList ? [...fileList] : [];

  function refreshData() {
    apiCall(`getdata/customers`).then((data) => { setData(data); });
  }
  if (!data) { refreshData(); }


  function rowClicked(username) {
    setSelectedName(username);
  }

  function statusChanged(id, status) {
    apiCall(`update/customers/${id}`, { status },).then((data) => { setData(data); });
    setTimeout(refreshData, 500);
  }

  let statusOptions = [
    <option key={0}>Görsellerin Yüklenmesi Bekleniyor.</option>,
    <option key={1}>Müşterinin Seçim Yapması Bekleniyor.</option>,
    <option key={2}>Fotoğraf Seçimi Tamamlandı.</option>,
    <option key={3}>Baskı İşlemi Bekleniyor.</option>,
    <option key={4}>Baskı İşlemi Tamamlandı.</option>,
    <option key={5}>Albüm Kargo'ya verildi.</option>,
    <option key={5}>Albüm Müşteriye İletildi.</option>,
  ];

  console.log(data);
  let table = [];
  if(data) {
    for(let i = 0; i < data.length; i++) {

      if(data[i].username === selectedName) { selectedFiles = data[i].selected_images; }

      let imgs= "Yapılmadı";
      if(data[i].selected_images != "") { imgs = "Yapıldı"; }


      table.push(
        <tr key={i} onClick={() => rowClicked(data[i].username)} className={data[i].username === selectedName ? "employee-selected" : ""} >
          <td>{data[i].username}</td>
          <td>{imgs}</td>
          <td><select value={data[i].status} onChange={(e) => statusChanged(data[i].id, e.target.value)}>{statusOptions}</select></td>
          <td><button type="button" className="btn p-2 px-3" onClick={copyFiles}>Gönder</button></td>
          <td><button type="button" className="btn p-2 px-3" onClick={copySelectedFiles}>Al</button></td>
        </tr>
      )
    }
  }

  return (
    <>
      <div className="row justify-content-center text-center">
        <div className="col-8">
          <h1>{username}</h1>
          <h2>{userData?.studio}</h2>
          <div className="my-4">
            <button type="button" onClick={handleFileUpload} className="btn btn-secondary p-2 px-3 me-2">Görsel Yükle</button>
            <input type="file" onChange={changeFile} multiple />
          </div>
          <div className="admin-log-table">
            <table>
              <thead>
                <tr>
                  <th>Kullanıcı Adı</th>
                  <th>Fotoğraf Seçimi</th>
                  <th>Durum</th>
                  <th colspan="2">Dosya Aktarımı</th>
                </tr>
              </thead>
              <tbody>
                {table}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}