import React, { useState } from 'react';
import apiCall from '../scripts/apiCall.js';

export default function AdminPage({page, changePage}) {
    const [view, setView] = useState("log");
    const [data, setData] = useState(null);
    const [selectedViewAndId, setSelectedViewAndId] = useState([null, null]);

    function rowClicked(id) {
        setSelectedViewAndId([view, id]);
    }

    function deleteRow() {
        if(!selectedViewAndId[1] || (selectedViewAndId[0] !== view)) {
            alert("Lütfen silmek istediğiniz kaydı seçiniz.");
            return;
        };

        const confirm = window.confirm(`${selectedViewAndId[1]} nolu kaydı ${view} tablosundan silmek istediğinize emin misiniz?`);
        if(confirm.valueOf() === false) return;
        
        apiCall(`delete/${view}s/${selectedViewAndId[1]}`).then(() => {
            setSelectedViewAndId([null, null]);
            apiCall(`getdata/${view}s`).then(data => setData(data));
        });
    }

    function addRow() {
        if(view === "log") {
            alert("Kayıtlar otomatik oluşturulur.");
            return;
        }

        let username = prompt(`Yeni ${view} Kullanıcısı:`);
        let password = prompt(`${username} Kullanıcısının Şifresi:`);
        let studio = prompt(`${username} Kullanıcısının Stüdyosu:`);        
        if (view === "employee") {
            let os = prompt(`${username} Kullanıcısının İşletim Sistemi (windows/macos):`);
            let path = prompt(`${username} Kullanıcısının Dosya Yolu:`);
            if(!username || !password || !studio || !os || !path) {
                alert("Kullanıcı adı, Şifre, Stüdyo, İşletim sistemi, Dosya yolu boş bırakılamaz.");
                return;
            }
            apiCall(`insert/${view}s`, {username, password, studio, os, path}).then(() => {
                setSelectedViewAndId([null, null]);
                apiCall(`getdata/${view}s`).then(data => setData(data));
            });
        }
        else {
            let status = prompt(`${username} Kullanıcısının Durumu:`);
            if(!username || !password || !studio || !status) {
                alert("Kullanıcı adı, Şifre, Stüdyo, İşletim sistemi, Dosya yolu boş bırakılamaz.");
                return;
            }
            apiCall(`insert/${view}s`, {username, password, studio, status, selected_images: ""}).then(() => {
                setSelectedViewAndId([null, null]);
                apiCall(`getdata/${view}s`).then(data => setData(data));
            });
        }
    }

    function updateRow() {
        if(view === "log") {
            alert("Kayıtlar otomatik oluşturulur.");
            return;
        }
        if(!selectedViewAndId[1] || (selectedViewAndId[0] !== view)) {
            alert("Lütfen değişiklik yapmak istediğiniz kaydı seçiniz.");
            return;
        };

        let username = prompt(`Yeni ${view} Kullanıcısı:`);
        let password = prompt(`${username} Kullanıcısının Şifresi:`);
        let studio = prompt(`${username} Kullanıcısının Stüdyosu:`);
        if(view === "employee") {
            let os = prompt(`${username} Kullanıcısının İşletim Sistemi (windows/macos):`);
            let path = prompt(`${username} Kullanıcısının Dosya Yolu:`);
            if(!username || !password || !studio || !os || !path) {
                alert("Kullanıcı adı, Şifre, Stüdyo, İşletim sistemi, Dosya yolu boş bırakılamaz.");
                return;
            }
            apiCall(`update/${view}s/${selectedViewAndId[1]}`, {username, password, studio, os, path}).then(() => {
                setSelectedViewAndId([null, null]);
                apiCall(`getdata/${view}s`).then(data => setData(data));
            });
        }
        else {
            let status = prompt(`${username} Kullanıcısının Durumu:`);
            if(!username || !password || !studio || !status) {
                alert("Kullanıcı adı, Şifre, Stüdyo, İşletim sistemi, Dosya yolu boş bırakılamaz.");
                return;
            }
            apiCall(`update/${view}s/${selectedViewAndId[1]}`, {username, password, studio, status}).then(() => {
                setSelectedViewAndId([null, null]);
                apiCall(`getdata/${view}s`).then(data => setData(data));
            });
        }
    }

    apiCall(`getdata/${view}s`).then(data => setData(data));
    
    let table = [];
    let thead;
    if (data) {
        switch (view) {
            case "log":
                thead = <thead><th>id</th><th>Kayıt</th></thead>;
                for (let i = data.length - 1; i >= 0; i--) {
                    const selected = ((selectedViewAndId[0] === view) && (selectedViewAndId[1] === data[i].id)) ? "selected" : "";
                    table.push(<tr onClick={() => rowClicked(data[i].id)} className={selected} key={`log-${i}`}>
                        <td>{data[i].id}</td>
                        <td>{data[i].log}</td>
                    </tr>);
                }
                break;
            case "employee":
                thead = <thead><th>id</th><th>Kullanıcı Adı</th><th>Şifre</th><th>Stüdyo</th><th>Sistem</th><th>Masaüstü</th></thead>;
                for (let i = data.length - 1; i >= 0; i--) {
                    const selected = ((selectedViewAndId[0] === view) && (selectedViewAndId[1] === data[i].id)) ? "selected" : "";
                    table.push(<tr onClick={() => rowClicked(data[i].id)} className={selected} key={`employee-${i}`}>
                        <td>{data[i].id}</td>
                        <td>{data[i].username}</td>
                        <td>{data[i].password}</td>
                        <td>{data[i].studio}</td>
                        <td>{data[i].os}</td>
                        <td>{data[i].path}</td>
                    </tr>);
                }
                break;
            case "customer":
                thead = <thead><th>id</th><th>Kullanıcı Adı</th><th>Şifre</th><th>Stüdyo</th><th>Durum</th></thead>;
                for (let i = data.length - 1; i >= 0; i--) {
                    const selected = ((selectedViewAndId[0] === view) && (selectedViewAndId[1] === data[i].id)) ? "selected" : "";
                    table.push(<tr onClick={() => rowClicked(data[i].id)} className={selected} key={`customer-${i}`}>
                        <td>{data[i].id}</td>
                        <td>{data[i].username}</td>
                        <td>{data[i].password}</td>
                        <td>{data[i].studio}</td>
                        <td>{data[i].status}</td>
                    </tr>);
                }
                break;
            default:
                table = <tr><td>Veri yok</td></tr>;
                break;
        }
    } else {
        table = <tr><td>Veri yok</td></tr>;
    }

    /*function deleteRow(id) {
        apiCall(`delete/${view}s/${id}`);
    }*/
    
    return (
        <div className="row justify-content-center mt-4">
            <div className="col-10">
                
                <div className="row my-4">
                    <div className="col-12 text-center">
                        <h3>YÖNETİCİ ARAYÜZÜ</h3>
                    </div>
                </div>

                <div className="row justify-content-center mt-4 admin-view-buttons">
                    <div className="col-3 text-center">
                        <button type="button" onClick={() => setView("employee")} aria-selected={view === "employee"} className='admin-view-button'>Personel</button>
                        <button type="button" onClick={() => setView("log")} aria-selected={view === "log"} className='admin-view-button'>Son İşlemler</button>
                        <button type="button" onClick={() => setView("customer")} aria-selected={view === "customer"} className='admin-view-button'>Müşteri</button>
                    </div>
                </div>
                <div className='row justify-content-center mb-4 admin-command-buttons'>
                    <div className='col-3 text-center'>
                        <button type='button' onClick={() => addRow()} className='admin-command-button'>Ekle</button>
                        <button type='button' onClick={() => updateRow()} className='admin-command-button'>Güncelle</button>
                        <button type='button' onClick={() => deleteRow()} className='admin-command-button'>Sil</button>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-10 admin-log-table">
                        <table>
                            {thead}
                            <tbody>
                                {table}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    )
}