export default function TopRow({page, changePage}) {
    let button;
    if (page === "home") {
      button = <button type="button" onClick={() => changePage("loginCustomer")} className='login-button float-none'>Müşteri Girişi</button>
    }
    else {
      button = <button type="button" onClick={() => changePage("home")} className='login-button float-none'>Ana Sayfa</button>
    }

    return (
      <div className='row top-row'>
        <div className='col-4'></div>
        <div className='col-4 text-center'>
          <h1 className='display-5 logo-text'>P H O T O N</h1>
        </div>
        <div className='col-4'>
            {button}
        </div>
      </div>
    )
  }