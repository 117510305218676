
export default function apiCall(command, data, content_type = "json") {
  
  if (content_type === "json") {
    return fetch(`https://92.205.231.209:7001/api/${command}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },    
      body: JSON.stringify(data)
    }).then(response => response.json());
  }
  else if (content_type === "multipart") {
    return fetch(`https://92.205.231.209:7001/api/${command}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },    
      body: data
    }).then(response => response.json());
  }
  else {
    console.log("Content type boş veya yanlış girdin.");
  }
}

function fetcher(command, data, content_type) {
  return fetch(`https://localhost:7001/api/${command}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': `${content_type}`,
    },    
    body: data
  }).then(response => response.json());
}
