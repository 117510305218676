import { useHref } from "react-router-dom";
import apiCall from "../scripts/apiCall";
import { useEffect, useState } from 'react'

export default function CustomerPage({username, page, changePage}) {
  const [imageurls, setImageurls] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  let res;

  useEffect(() => {
    apiCall(`getcustomer/${username}`).then((data) => {
      setCustomerData(data);
    });
  }, [username]);


  console.log(customerData);

  const sendImages = () => {
    let conf = window.confirm("Fotoğraf seçimlerinizi göndermek istediğinizden emin misiniz? Bu işlem geri alınamaz.");
    if(!conf) return;
    const selectedImages = document.querySelectorAll("input[type=checkbox]:checked");
    const selectedImageUrls = Array.from(selectedImages).map((image) => image.name);
    apiCall(`setselectedimages`, {customerName: username, imageurls: selectedImageUrls}).then((data) => {
      if(data.success) {
        console.log(data.message);
      }
    });
    apiCall(`update/customers/${customerData.id}`, { status: "Fotoğraf Seçimi Tamamlandı." });
    apiCall(`getcustomer/${username}`).then((data) => { setCustomerData(data); });
    alert("Fotoğraf Seçimleriniz İletiltdi");
  }




  switch (customerData?.status) {
    case "Görsellerin Yüklenmesi Bekleniyor.":
      res = 
      <div className="d-flex justify-content-center align-items-center h-100">
        Fotoğraflarınız hazır hale geldikten sonra bu sayfaya yüklenecektir...
      </div>
      break;
    case "Müşterinin Seçim Yapması Bekleniyor.":
      if(imageurls.length === 0) {
        apiCall(`getimages/${username}`).then((data) => {      
          console.log(data);
          if (data.length === 0) return;
          setImageurls(data);
        });
      }
      res = 
      <div>
        <div className="row justify-content-center customer-images">
          {imageurls.map((imageurl) =>
            <div key={imageurl}>
              <a href={`https://92.205.231.209:7001/customer_images/${customerData?.username}/${imageurl}`} target="_blank" rel="noopener noreferrer"><img className="img-fluid customer-image" src={`https://92.205.231.209:7001/customer_images/${customerData?.username}/${imageurl}`} /></a>
              <input name={imageurl} type="checkbox" />
            </div>
          )}
        </div>
        <div className="row justify-content-center">
            <button type="button" onClick={() => sendImages()} className="btn btn-primary customer-button">Gönder</button>
        </div>
      </div>
      break;
    case "Fotoğraf Seçimi Tamamlandı.":
    case "Baskı İşlemi Bekleniyor.":
    case "Baskı İşlemi Tamamlandı.":
    case "Albüm Kargoya Verildi.":
    case "Albüm Müşteriye İletildi.":
      let chosenImages = customerData?.selected_images.split(",");
      
      res = 
      <div className="row justify-content-center customer-images">
        {chosenImages.map((imageurl) =>
          <div key={imageurl}>
            <a href={`https://92.205.231.209:7001/customer_images/${customerData?.username}/${imageurl}`} target="_blank" rel="noopener noreferrer"><img className="img-fluid customer-image" src={`https://92.205.231.209:7001/customer_images/${customerData?.username}/${imageurl}`} /></a>
          </div>
        )}
      </div>
      break;
    default:
      res = <div>BEKLENMEDİK BİR HATA OLUŞTU. LÜTFEN BU NUMARADAN BİZE ULAŞINIZ: 0555555555</div>
      break;
  }

  console.log(imageurls);

  return (
    <div className="row customer-row justify-content-center text-center mt-4">
      <div className='col-8'>
        <div className="row justify-content-center">          
          <h1>{customerData?.username}</h1>
          <h3>{customerData?.studio} stüdyosu</h3>          
        </div>
        <div className="row justify-content-center mt-4 status-div">
          <b>Durum:</b> {customerData?.status}
        </div>
        {res}
      </div>
    </div>
  )
}
