export default function BotRow({page, changePage}) {
    let button;
    if (page === "home") {
      button = 
        <>
          <button type="button" onClick={() => changePage("loginEmployee")} className='login-button me-2 float-none'>Personel Girişi</button>
          <button type="button" onClick={() => changePage("loginAdmin")} className='login-button ms-2 float-none'>Admin Girişi</button>
        </>;
    }
    else {
      button = <button type="button" onClick={() => changePage("home")} className='login-button float-none'>Ana Sayfa</button>
    }
    return (
      <div className='row bottom-row'>
        <div className='col-12 text-center'>
          <p>Photon (c) 2024. Tüm Hakları Saklıdır.</p>
        </div>
        <div className='col-12 text-center'>
          {button}
        </div>
      </div>
    )
  }