export default function HomeRow() {
    return (
      <>
      <div className='row justify-content-center my-4'>
        <div className='col-12'><h2 className='slogan-text'>Mutluluğa Hizmet Ediyoruz...</h2></div>
        <div className='col-12'><h3 className='slogan-text'>Ana sayfa buraya gelecek</h3></div>
      </div>
      
      <div className='row justify-content-center my-4'>
        <div className='col-2'></div>
        <div className='col-8'>
          <div className='main-img-wrapper'>
            <img className='main-img' src="https://bogazda.org/blog/wp-content/uploads/2019/05/istanbul-da-dugun-fotografi-icin-mekanlar-7.jpg" />
          </div>
        </div>
        <div className='col-2'></div>
      </div>
      </>
    )
  }