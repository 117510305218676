import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useState } from 'react'
import TopRow from './components/top.jsx'
import BotRow from './components/bot.jsx'
import HomeRow from './components/home.jsx'
import LoginPage from './components/loginPage.jsx'
import CustomerPage from './components/customerPage.jsx'
import EmployeePage from './components/employeePage.jsx'
import AdminPage from './components/adminPage.jsx'

import './App.css'

function App() {
  const [page, setPage] = useState("home");
  const [username, setUsername] = useState("");

  const changePage = (page) => {
    setPage(page);
  }

  let pageComp;  
  switch (page) {
    case "home":
      pageComp = <HomeRow page={page} changePage={changePage} />
      break;
    case "loginCustomer":
      pageComp = <LoginPage setUserName={setUsername} loginfor="customer" page={page} changePage={changePage} />
      break;
    case "loginEmployee":
      pageComp = <LoginPage setUserName={setUsername} loginfor="employee" page={page} changePage={changePage} />
      break;
    case "loginAdmin":
      pageComp = <LoginPage setUserName={setUsername} loginfor="admin" page={page} changePage={changePage} />
      break;
    case "customer":
      pageComp = <CustomerPage username={username} page={page} changePage={changePage} />
      break;
    case "employee":
      pageComp = <EmployeePage username={username} page={page} changePage={changePage} />
      break;
    case "admin":
      pageComp = <AdminPage page={page} changePage={changePage} />
      break;
    default:
      pageComp = <HomeRow page={page} changePage={changePage} />
      break;
  }

  return (
    <div className='container-fluid'>
    <TopRow page={page} changePage={changePage} />
    <div className='row main'>
      {pageComp}
    </div>    
    <BotRow page={page} changePage={changePage} />
  </div>
  )
}



export default App

/*export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
        </Routes>
      </Layout>
    );
  }
}*/
