import apiCall from "./apiCall.js";
export default function selectedCopier(user_os, studioName, customer_name, user_path, files) {
    let res = user_os === "windows" ? copyBat : newCopySh;

    let selectedFiles = files.split(",").map(file => file.trim());
    selectedFiles+="," + files.split(",").map(file => file.trim().substr(0, file.lastIndexOf(".")) + ".RAW").join(",");
    selectedFiles = selectedFiles.toString().split(",");
    console.log(selectedFiles);

    apiCall(`getstudiodata/${studioName}`).then((data) => {
        res = res.replace("@selected_files", "\"" + selectedFiles.join("\" \"") + "\"");
        //res = res.replace("@source_dir", customer_name);
        res = res.replace("@user_path", user_path);        

        res = res.replace("@ip", data.ip);
        res = res.replaceAll("@folder", data.folder);
        res = res.replaceAll("@customer_name", customer_name);
        //if (user_os !== "windows") { data.uname_pass = data.uname_pass.replace(" ", "%"); }
        let uname = data.uname_pass.split(" ")[0];
        let pass = data.uname_pass.split(" ")[1];
        res = res.replace("@uname", uname);
        res = res.replace("@pass", pass);
        //res = res.replace("@uname_pass", data.uname_pass);

        //let sectikleriFile = (user_os === "windows" ? "\\\\" : "/") + customer_name;
        //res = res.replace("@target_dir", user_path + `${sectikleriFile} Seçtikleri/`);
    }).then(() => {       
        const element = document.createElement("a");
        const file = new Blob([res], {type: 'application/octet-stream'});
        element.href = URL.createObjectURL(file);
        element.download = "dosya_al." + (user_os === "windows" ? "bat" : "sh");
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click(); 
    });    
}

const newCopySh = `
#!/bin/bash

# Kullanıcı ayarları
SHARE="@ip/@folder"
MOUNT_POINT="$HOME/temp_mount"
USER="@uname"
PASSWORD="@pass"
SOURCE_DIR="@customer_name" # İçeriği alınacak uzak klasör
DEST_DIR="@user_path/@customer_name Seçtikleri" # Masaüstünde oluşturulacak hedef klasör
files=(@selected_files)

# İşletim sistemini belirle
if [[ "$(uname)" == "Darwin" ]]; then
    MOUNT_CMD="sudo mount_smbfs //$USER:$PASSWORD@$SHARE $MOUNT_POINT"
elif [[ "$(uname)" == "Linux" ]]; then
    MOUNT_CMD="sudo mount.cifs '//$SHARE' '$MOUNT_POINT' -o user=$USER,pass=$PASSWORD"
else
    echo "Unsupported OS."
    exit 1
fi

# Klasör var mı kontrol et, yoksa oluştur
if [ ! -d "$MOUNT_POINT" ]; then
    mkdir -p "$MOUNT_POINT"
fi
if [ ! -d "$DEST_DIR" ]; then
    mkdir -p "$DEST_DIR"
fi

# SMB paylaşımını bağla
echo "SMB paylaşımına bağlanıyor..."
if eval $MOUNT_CMD; then
    echo "Başarıyla bağlandı."
    
    # Kaynak klasörün varlığını kontrol et
    if [ -d "$MOUNT_POINT/$SOURCE_DIR" ]; then
        echo "$SOURCE_DIR 'deki dosyalar $DEST_DIR 'e kopyalanacak..."
        
        for file in \${files[@]}; do
            if sudo cp -R "$MOUNT_POINT/$SOURCE_DIR/$file" "$DEST_DIR"; then
                echo "$file hedefe kopyalandı."
            else
                echo "HATA: $file kopyalanırken bir hata oluştu."
            fi
        done
    else
        echo "HATA: SMB paylaşımında $SOURCE_DIR mevcut değil."
    fi

    # SMB paylaşımını kaldır
    echo "SMB paylaşımı kapatılıyor..."
    if [[ "$(uname)" == "Darwin" ]]; then
        sudo umount -f "$MOUNT_POINT"
    else
        sudo umount -l "$MOUNT_POINT"
    fi
    echo "Başarıyla kapatıldı."
else
    echo "HATA: SMB paylaşımına bağlanılamadı."
fi
`

const copySh = `#!/bin/bash

# Belirli dosyaları SMB sunucusundan almak için

# SMB paylaşım yolu
SHARE_PATH="//@ip/@folder"

# SMB kullanıcı adı ve şifresi
SMB_USER_PASS="@uname_pass"

# Yerel hedef klasör
TARGET_DIR="@target_dir"

# Kopyalanacak dosyalar
files=(@selected_files)

# SMB paylaşımına bağlanabilir mi kontrol et
smbclient "$SHARE_PATH" -U "$SMB_USER_PASS" -c "ls" > /dev/null
if [ $? -ne 0 ]; then
    echo "SMB paylaşımına bağlanılamadı."
    exit 1
else
    echo "Bağlantı başarılı."
fi

# Yerel hedef klasörü oluştur (yoksa)
if [ ! -d "$TARGET_DIR" ]; then
    mkdir "$TARGET_DIR"
    echo "Yerel klasör $TARGET_DIR oluşturuldu."
fi

# Dosyaları kopyala
for file in $files; do
    echo "Kopyalanıyor: $file"
    smbclient "$SHARE_PATH" -U "$SMB_USER_PASS" -c "cd @customer_name; get \"$file\"" -D "$TARGET_DIR"
    if [ $? -eq 0 ]; then
        echo "$file başarıyla $TARGET_DIR klasörüne kopyalandı."
    else
        echo "$file kopyalanırken bir hata oluştu."
    fi
done
`


// set files=("file1.txt" "file2.txt" "file3.txt")
const copyBat = `
@echo off

:: Belirli dosyaları SMB sunucusundan almak için

:: SMB paylaşım yolu
set "SHARE_PATH=\\@ip\@folder"
set "SMB_USER_PASS=@uname_pass"

:: Yerel hedef klasör
set "TARGET_DIR=@target_dir"

:: Kopyalanacak dosyalar
set files=(@selected_files)

:: SMB paylaşımına bağlan
echo Bağlantı kuruluyor...
net use %SHARE_PATH% /user:%SMB_USER_PASS% >nul 2>&1
if not exist "%SHARE_PATH%" (
    echo "SMB paylaşımına bağlanılamadı."
    exit /b 1
)

:: Yerel klasörü oluştur (yoksa)
if not exist "%TARGET_DIR%" (
    mkdir "%TARGET_DIR%"
    echo "Yerel klasör %TARGET_DIR% oluşturuldu."
)

:: Dosyaları kopyala
for %%f in %files% do (
    echo Kopyalanıyor: %%f
    copy "%SHARE_PATH%\%%f" "%TARGET_DIR%"
    if %errorlevel%==0 (
        echo %%f başarıyla %TARGET_DIR% klasörüne kopyalandı.
    ) else (
        echo %%f kopyalanırken bir hata oluştu.
    )
)

:: Paylaşımı kapat
net use %SHARE_PATH% /delete >nul 2>&1

`
