import apiCall from "./apiCall";

export default function copier(os, studioName, customerName) {
    let res = os === "windows" ? copyBat : newCopySh;

    apiCall(`getstudiodata/${studioName}`).then((data) => {
        console.log(data);
        res = res.replaceAll("@ip", data.ip);
        res = res.replaceAll("@folder", data.folder);
        res = res.replaceAll("@customer_name", customerName);
        //if (os !== "windows") { data.uname_pass = data.uname_pass.replace(" ", "%"); }
        let uname = data.uname_pass.split(" ")[0];
        let pass = data.uname_pass.split(" ")[1];
        res = res.replaceAll("@uname", uname);
        res = res.replaceAll("@pass", pass);
        //res = res.replaceAll("@uname_pass", data.uname_pass);
    }).then(() => {        
        const element = document.createElement("a");
        const file = new Blob([res], {type: 'application/octet-stream'});
        element.href = URL.createObjectURL(file);
        element.download = "dosya_gonder." + (os === "windows" ? "bat" : "sh");
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    });
}

const newCopySh = `
#!/bin/bash

# Kullanıcı ayarları
SHARE="@ip/@folder"
MOUNT_POINT="$HOME/temp_mount"
USER="@uname"
PASSWORD="@pass"
TARGET_DIR="@customer_name"

# İşletim sistemini belirle
if [[ "$(uname)" == "Darwin" ]]; then
    MOUNT_CMD="sudo mount_smbfs //$USER:$PASSWORD@$SHARE $MOUNT_POINT"
elif [[ "$(uname)" == "Linux" ]]; then
    MOUNT_CMD="sudo mount.cifs '//$SHARE' '$MOUNT_POINT' -o user=$USER,pass=$PASSWORD"
else
    echo "Desteklenmeyen İşletim Sistemi."
    exit 1
fi

# Klasör var mı kontrol et, yoksa oluştur
if [ ! -d "$MOUNT_POINT" ]; then
    mkdir -p "$MOUNT_POINT"
fi

# SMB paylaşımını bağla
echo "Dosya paylaşımına bağlanıyor..."
if eval $MOUNT_CMD; then
    echo "Başarıyla bağlandı."
    
    # dosya aktarımında kontrol etmek için scriptin adını al
    SCRIPT_NAME=$(basename "$0")
    
    if [ ! -d "$MOUNT_POINT/$TARGET_DIR" ]; then
    	sudo mkdir -p "$MOUNT_POINT/$TARGET_DIR"
    fi
    
    echo "Dosyalar SMB paylaşımına aktarılıyor..."
    for file in *; do
        if [ "$file" != "$SCRIPT_NAME" ]; then
            if sudo cp -R "$file" "$MOUNT_POINT/$TARGET_DIR/"; then
                echo "$file aktarıldı."
            else
                echo "HATA: $file aktarılırken bir sorun oluştu."
            fi   
        fi
    done

    # SMB paylaşımını kaldır
    echo "SMB paylaşımı kapatılıyor..."
    if [[ "$(uname)" == "Darwin" ]]; then
        sudo umount -f "$MOUNT_POINT"
    else
        sudo umount -l "$MOUNT_POINT"
    fi
    echo "Başarıylı kapatıldı."
else
    echo "HATA: SMB paylaşımına bağlanılamadı."
fi
`

const copySh = `#!/bin/bash

# Hedef klasörü ve paylaşım yolunu belirle
TARGET_DIR="@customer_name"
SHARE_PATH="//@ip/@folder"

# SMB paylaşımına anonim veya kullanıcı adıyla bağlanmak için gerekli komutlar
SMB_USER_PASS="@uname_pass"

# Klasör mevcut mu kontrol et
smbclient "$SHARE_PATH" -U "$SMB_USER_PASS" -c "ls" | grep "$TARGET_DIR" > /dev/null

if [ $? -ne 0 ]; then
    # Klasör mevcut değilse oluştur
    smbclient "$SHARE_PATH" -U "$SMB_USER_PASS" -c "mkdir \\"$TARGET_DIR\\""
    echo "Klasör $TARGET_DIR oluşturuldu."
else
    echo "Klasör $TARGET_DIR zaten mevcut."
fi

# Scriptin kendi adını al
SCRIPT_NAME=$(basename "$0")

# Bulunduğun klasördeki tüm dosya ve dizinleri hedef klasöre kopyala, kendi scriptini hariç tut
for file in *; do
    if [ "$file" != "$SCRIPT_NAME" ]; then
        if [ -d "$file" ]; then
            # Dizin ise smbclient ile kopyala (alt dizine geçerek)
            smbclient "$SHARE_PATH" -U "$SMB_USER_PASS" -c "cd \\"$TARGET_DIR\\"; mkdir \"$file\"; prompt OFF; recurse ON; mput \"$file/*\""
        else
            # Dosya ise smbclient ile doğrudan kopyala
            smbclient "$SHARE_PATH" -U "$SMB_USER_PASS" -c "cd \\"$TARGET_DIR\\"; put \\"$file\\""
        fi
    fi
done

# İşlem başarılı olursa geri bildirim ver
if [ $? -eq 0 ]; then
    echo "Dosyalar başarıyla $SHARE_PATH/$TARGET_DIR klasörüne kopyalandı."
else
    echo "Dosyalar kopyalanırken bir hata oluştu."
fi
`;



const copyBat = `@echo off

:: Hedef klasörü ve paylaşım yolunu belirle
set "TARGET_DIR=Şehsuvar Boyacı"
set "SHARE_PATH=\\\\@ip\\@folder"
set "SMB_USER_PASS=@uname_pass"

:: Hedef klasör mevcut mu kontrol et
net use %SHARE_PATH% /user:%SMB_USER_PASS% >nul 2>&1
if exist "%SHARE_PATH%\\%TARGET_DIR%" (
    echo Klasör %TARGET_DIR% zaten mevcut.
) else (
    :: Klasör mevcut değilse oluştur
    mkdir "%SHARE_PATH%\\%TARGET_DIR%"
    echo Klasör %TARGET_DIR% oluşturuldu.
)

:: Scriptin kendi adını al
set "SCRIPT_NAME=%~nx0"

:: Bulunduğun klasördeki tüm dosya ve dizinleri hedef klasöre kopyala, kendi scriptini hariç tut
for %%f in (*) do (
    if not "%%f"=="%SCRIPT_NAME%" (
        if exist "%%f\\" (
            :: Dizin ise xcopy ile kopyala
            xcopy /e /i "%%f" "%SHARE_PATH%\\%TARGET_DIR%\\%%f"
        ) else (
            :: Dosya ise copy ile kopyala
            copy "%%f" "%SHARE_PATH%\\%TARGET_DIR%"
        )
    )
)

:: İşlem başarılı olursa geri bildirim ver
if %errorlevel%==0 (
    echo Dosyalar başarıyla %SHARE_PATH%\\%TARGET_DIR% klasörüne kopyalandı.
) else (
    echo Dosyalar kopyalanırken bir hata oluştu.
)

:: Paylaşımı kapat
net use %SHARE_PATH% /delete >nul 2>&1

`